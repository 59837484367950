import "./App.css";
import { Page } from "./components/Page";

function App() {
  return (
    <div className="App">
      <Page></Page>
    </div>
  );
}

export default App;
