import * as React from "react";
import { IArtist, ITrack } from "./Page";
import classNames from "classnames";

interface IProps {
  artist: IArtist;
  setTrack: Function;
  track?: ITrack;
}

export const Artist: React.FC<IProps> = ({ artist, setTrack, track }) => {
  return (
    <>
      {artist.tracks.map((current) => (
        <div
          key={current.id}
          id={current.id}
          className={classNames(
            " text-white  py-3 px-2 my-0 mt-0 border-t border-gray-500 ",
            current.id === track?.id ? "bg-green-800" : "bg-gray-900"
          )}
        >
          <button
            className="flex w-full text-left  align-content-center items-center space-x-2"
            onClick={() => setTrack(current)}
          >
            <div
              className={classNames(
                "w-6 h-6 rounded-full border text-white flex justify-center items-center pb-1",
                current.id === track?.id ? "bg-green-600" : "bg-pink-600"
              )}
            >
              <p className="font-mono text-lg">✮</p>
            </div>
            <div>
              <div className="font-bold ">{current.title}</div>
              <div>{artist.name}</div>
            </div>
          </button>
        </div>
      ))}
    </>
  );
};
