import * as React from "react";
import "video-react/dist/video-react.css";
import {
  Player,
  ControlBar,
  CurrentTimeDisplay,
  DurationDisplay,
  PlayerReference,
} from "video-react";
import { ITrack } from "./Page";

interface IProps {
  track: ITrack;
  shuffle: Function;
}

export const Video: React.FC<IProps> = ({ track, shuffle }) => {
  const [player, setPlayer] = React.useState<PlayerReference | null>();

  React.useEffect(() => {
    player?.load();
  }, [track, player]);

  function handleBind(state: any) {
    if (state.ended) {
      shuffle();
    }
  }

  React.useEffect(() => {
    if (player) {
      player.subscribeToStateChange(handleBind);
    }
  });

  return (
    <div className="w-full flex">
      <div className="m-auto  w-96 flex justify-center py-2">
        <Player
          ref={(player) => {
            setPlayer(player);
          }}
          playsInline={true}
          autoPlay={true}
          fluid={false}
          height={200}
        >
          <source src={`/data/medias/${track.media}`} />

          <ControlBar>
            <CurrentTimeDisplay order={1} />
            <DurationDisplay order={2} />
          </ControlBar>
        </Player>
      </div>
    </div>
  );
};
