import React, { useCallback, useEffect, useState } from "react";
import { Artist } from "./Artist";
import { Video } from "./Video";
import { ForwardIcon } from "@heroicons/react/24/outline";

export interface IArtist {
  id: string;
  name: string;
  tracks: ITrack[];
}

export interface ITrack {
  id: string;
  title: string;
  media: string;
}

export const Page: React.FC = () => {
  const [artists, setArtists] = React.useState<IArtist[]>();

  const [track, setTrack] = useState<ITrack>();

  const load = useCallback(() => {
    fetch("/api/artists?order[name]=asc&tracks.video=false")
      .then((response) => response.json())
      .then((entries) => {
        setArtists(entries["hydra:member"]);
      });
  }, []);

  const getRandomTrack = () => {
    let tracks: ITrack[] = [];

    artists
      ?.map((artist) => artist.tracks)
      .forEach((artistTracks) =>
        artistTracks.forEach((track) => tracks.push(track))
      );

    return tracks
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .pop()?.value;
  };

  const shuffle = () => {
    const track = getRandomTrack();
    if (track) {
      setTrack(track);
      scrollTo(track);
    }
  };

  const scrollTo = (track?: ITrack) => {
    var myElement = document.getElementById(track?.id + "");
    var topPos = myElement?.offsetTop;
    const list = document.getElementById("list");
    if (topPos && list) {
      list.scrollTop = topPos - 300;
    }
  };

  useEffect(() => load(), []);

  return (
    <div className="bg-gradient-to-b from-black via-black to-pink-500 h-screen flex flex-col justify-between">
      <div className="flex justify-center text-white center top-0 h-64">
        {track && <Video track={track} shuffle={shuffle} />}
      </div>
      <div id="list" className="h-full pb-32 lg:px-64 overflow-auto text-xs">
        {artists?.map((artist, index) => (
          <Artist
            key={index}
            artist={artist}
            setTrack={setTrack}
            track={track}
          />
        ))}
      </div>
      <div
        className="mt-64  space-x-2 p-2 flex items-center justify-end  w-full bg-black border-t text-white border-gray-500 lg:px-64
                      bottom-0 fixed"
      >
        <button className="text-xs" onClick={() => scrollTo(track)}>
          {track?.title}
        </button>
        <ForwardIcon onClick={() => shuffle()} className="w-5" />
      </div>
    </div>
  );
};
